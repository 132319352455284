<template>
  <v-container :style="{ maxWidth: '1318px' }" class="mt-8">
    <v-breadcrumbs :items="breadcrumbs" class="pa-0 ma-0" />
    <div class="font-weight-bold text-h4">{{ $t('label.creditCards') }}</div>
    <div class="my-4 credit-card__header-container">
      <div class="my-4 credit-card__banner-container">
        <img :src="bannerUrl" alt="Credit Card Banner" title="Credit Cards | Earn Up to RM150 Rebate Voucers" class="credit-card__banner rounded" />
      </div>
      <div class="credit-card__banner-header text-uppercase">
        <h3 class="d-none d-lg-block">{{ $t('label.creditCardHeader') }}</h3>
        <h3 class="d-block d-lg-none mb-2">
          <p class="text-body-2 font-weight-bold mb-0">{{ $t('label.creditCardHeaderMobile_1') }}</p>
          <p class="text-h5 font-weight-bold mb-0">{{ $t('label.creditCardHeaderMobile_2') }}</p>
        </h3>
        <p class="mb-0 caption">{{ $t('label.creditCardSubheader') }}</p>
      </div>
    </div>

    <div class="card-container">
      <div class="d-flex flex-column flex-md-row align-center" :key="index" v-for="(card, index) in creditCards">
        <div class="d-flex pa-6 border rounded-xl credit-card__underlay-desktop mb-2 mb-md-8">
          <img class="credit-card__icon-desktop" :src="card.imgUrl" :alt="card.title + ' - Image'" :title="card.title + ' - Image'" />
        </div>

        <div class="px-4 flex-grow-1 align-self-start">
          <div>
            <div class="font-weight-bold text-md-h5 text-h6">{{ card.title }}</div>
            <div class="my-4">
              <ul class="order-3 order-sm-2 mb-4">
                <li :key="feature" v-for="feature in card.features" class="text-body-2 text-md-body-1">
                  {{ feature }}
                </li>
              </ul>
              <div class="mb-4 mt-0 mt-sm-4 order-2 order-sm-3 credit-card-requirements">
                <div class="font-italic font-weight-bold text-body-2 text-md-body-1">{{ $t('label.requirements') }}</div>
                <div :key="requirement" v-for="requirement in card.requirements" class="font-italic text-body-2 text-md-body-1">{{ requirement }}</div>
              </div>
              <div class="d-block d-md-none text-body-2 font-italic">
                <template v-for="(document, i) in card.documents">
                  <a :key="document.name" :href="document.link" target="_blank">{{ i === 0 ? '*' : '' }}{{ document.name }}</a>
                  <span v-if="i < card.documents.length - 1" :key="`${document.name}-separator`" v-text="' | '"></span>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div class="my-4 caption font-italic">
          <div>
            <app-button :title="$t('action.applyOnlineNow')" :action="card.onClick" type="default" />
          </div>
          <div class="d-none d-md-block">
            <span>{{ $t('label.clickHereFor') }}{{ ' ' }}</span>

            <template v-for="(document, i) in card.documents">
              <a :key="document.name" :href="document.link" class="font-italic" target="_blank">{{ document.name }}</a>
              <span :key="`${document.name}-separator`" v-text="getSeparators(i, card.documents.length)" />
            </template>
          </div>
        </div>
        <v-divider v-if="index < creditCards.length - 1" class="align-self-stretch d-md-none py-2"></v-divider>
      </div>
    </div>
  </v-container>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { LOOKUPS_CREDIT_CARDS } from '@/store/lookup.module'

export default {
  name: 'creditCard',
  data() {
    return {}
  },
  mounted() {
    this.$store.dispatch(LOOKUPS_CREDIT_CARDS)
  },
  computed: {
    bannerUrl() {
      return this.$vuetify.breakpoint.smAndUp ? '/static/images/creditCards/banner.png' : '/static/images/creditCards/banner-mobile.png'
    },
    creditCards() {
      return this.$store.state.lookup.creditCards.map((o) => {
        let documents = [
          { name: this.$t('label.tncs'), link: o.tncUrl },
          { name: this.$t('label.faqs'), link: o.faqUrl },
          { name: this.$t('label.pds'), link: o.pdsUrl }
        ]

        return {
          title: o.title,
          features: o.features,
          requirements: o.requirement,
          imgUrl: o.imgUrl,
          uuid: o.uuid,
          documents: documents,
          onClick: () => {
            this.$router.push({
              name: ROUTE_NAME.CREDIT_CARD_APPLY,
              params: { title: o.title, creditCardUuid: o.uuid, documents: documents }
            })
          }
        }
      })
    },
    breadcrumbs() {
      return [
        {
          text: 'Unbox Service'
        },
        {
          text: 'Cards'
        }
      ]
    }
  },
  methods: {
    getSeparators(index, length) {
      if (index < length - 2) {
        return ', '
      } else if (index === length - 2) {
        return ' & '
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
img {
  max-width: 100%;
  height: 100%;
}

/* .card-container {
  display: grid;
  row-gap: 2rem;
} */

.credit-card__banner {
  width: 100%;
  min-height: 100px;
  object-fit: cover;
  object-position: right center;
}

.credit-card__icon-mobile {
  max-width: 128px;
  width: 25vw;
}

.credit-card__underlay-desktop {
  max-height: 360px;
  max-width: 360px;
  flex-shrink: 0;
}

.credit-card__icon-desktop {
  min-width: 128px;
  width: 100%;
  height: auto;
}

.credit-card-requirements {
  max-width: 50vw;
}

.credit-card__header-container {
  position: relative;
  h3 {
    font-size: 2rem;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 1.2rem;
  }
}

@media (min-width: 1264px) {
  .credit-card__banner-container {
    height: 180px;
  }

  .credit-card__banner-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 4rem;
    max-width: 500px;

    h3 {
      margin-bottom: 0;
    }

    p {
      font-size: 0.8rem;
    }
  }
}
</style>
